import React from "react";
import "./App.css";

function App() {
  return (
    <div className="container">
      <h1>Hello!</h1>
      <p>
        I'm Rasmus Makwarth. My latest project is{" "}
        <a href="https://bucket.co">Bucket</a>.
        <br />
        Previously, I was founder of <a href="https://opbeat.com">
          Opbeat
        </a>{" "}
        (acq. by Elastic), and some failed startups before that. I'm a Product
        guy, hacker, angel investor. Based in lovely Copenhagen.{" "}
        <a href="https://twitter.com/makwarth">Twitter</a> and{" "}
        <a href="https://www.linkedin.com/in/makwarth/">Linkedin</a>.
      </p>
    </div>
  );
}

export default App;
